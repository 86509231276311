@font-face {
  font-family: "lores12ot-reg";
  src: url("./fonts/lores12ot-reg-webfont.woff2") format("woff2"),
    url("./fonts/lores12ot-reg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.overall-background {
  height: 100vh;
}

.background-quiz-selection {
  background-color: #231f2e;
}

.new-question-config-box {
  background-color: #2e2a38;
  cursor: pointer;
}

.quizselectconfigrow {
  padding-left: 0px !important;
}

.quiz-select-section-config {
  height: 80px;
  border-left: none;
  border-top: 0.5px solid #4f4a5b;
  border-right: none;
  border-bottom: 0.5px solid #4f4a5b;
}

.new-question-config-box {
  background-color: #2e2a38;
  cursor: pointer;
}

.new-question-config-txt {
  color: #ffffff;
  font-size: 24px;
}

.make-new-quiz {
  margin-top: 30px;
  margin-left: 25px;
}

.new-quiz-img {
  margin-top: 30px;
}

.quiz-select-section-config-selected {
  background-color: #201d2b !important;
  border-top: 1px solid #a237f5 !important;
  border-bottom: 1px solid #a237f5 !important;

  /* border-color: #a237f5 !important; */

  /* margin-top: -1px; */
  /* z-index: 1000; */
}

.pointer-class-start:hover {
  cursor: pointer;
}

.quiz-is-selected {
  color: #a237f5 !important;
}

.selector-question-style {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 4px;
  margin-left: 25px;
  margin-top: 4px;
}

.dltbtnwrapper {
  text-align: right;
}

.p-style-config-delete {
  margin-right: 25px;
  margin-top: 10px;
  font-size: 10px;
  color: #ed3550;
  margin-bottom: 4px !important;
}

.btnrowwrappers {
  text-align: right;
  padding-right: 0px !important;
}

.p-style-config-edit {
  font-size: 15px;
  color: #4f4a5b;
  /* margin-left: 90%; */
  display: inline;
  margin-right: 25px;
  letter-spacing: 1px;
}

.p-style-config-active {
  color: #fed143;
}

.editbtnwrapper {
  text-align: right;
  /* margin-right: 6px; */
}

.edit-icon {
  margin-left: 5px;
}

.new-quizes-buttons {
  justify-content: end;
  margin-top: 60px !important;
}

.quiz-select-new-config {
  text-align: center;
}

.quiz-selection-top-margin {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.start-stop-buttons {
  height: 43px;
  border: none;
}

.left-box-polygon {
  clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
}

.end-quiz-btn-section {
  background-color: #2e2a38;
}

.select-btn-disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.right-box-polygon {
  clip-path: polygon(5% 0, 100% 0%, 100% 100%, 0 100%);
}

.create-move {
  margin-left: -24px !important;
}

.start-quiz-btn-section {
  background-color: #3ad219;
}

.grayscale-img:hover {
  filter: grayscale(20%);
}

.end-quiz-btn-section1 {
  background-color: #d2193a;
}

.pointer-class-new:hover {
  cursor: pointer;
}

.modal-header {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  justify-content: center !important;
}

.modal-Create {
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center !important;
}

.hrline {
  color: #ffffff;
}

.cancel-text-align {
  text-align: center;
}

.cancel-selector {
  font-size: 16px;
}

.navbar-background {
  background-color: #070215;
  height: 72px;
}

.navbar-logo {
  text-align: center;
  margin-top: 20px !important;
}

.navbar-headers {
  text-align: left;
  margin-top: 20px !important;
}

.select-stats-button-config {
  background-color: transparent;
  border: none;
}

.stats-p {
  font-size: 20px;
  color: #ffffff;
  opacity: 0.5;
}

.stats-p-active {
  opacity: 1;
}

.PerpBar {
  position: absolute;
  top: 60px;
  width: 100%;
}

.folder-row-margin {
  margin-top: 20% !important;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
.folder-row-margin::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.folder-row-margin::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.folder-row-margin::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.folder-row-margin::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.folder-edit-row {
  margin-left: 30px !important;
}

.folder-highlight-text-none {
  opacity: 50%;
}

.folder-hover {
  cursor: pointer;
}

.folder-text-margin1 {
  margin-left: 10px;
}

.folder-text-margin {
  margin-top: 10px !important;
}

.folder-highlight-text-none {
  opacity: 50%;
}

.folder-highlight-allquizzes {
  position: relative;
  z-index: 1;
  margin-top: -80px !important;
  /* margin-left: -20px !important; */
}

.folder-highlight-none {
  display: none;
}

.folder-edit-row {
  margin-left: 30px !important;
}

.folder-edit-delete {
  margin-top: 13px !important;
}

.folder-xicon {
  margin-left: -10px;
  cursor: pointer;
}

.folder-wrapper {
  cursor: pointer;
  width: 20% !important;
  margin-left: 10%;
  padding: 0px !important;
  margin-top: 5% !important;
}

.folder-input {
  border-color: #a237f5;
  background-color: #1d1a27;
  color: #ffffff;
  width: 100%;
  outline-color: #a237f5;
  color: #dddddd;
}

.confirm-color {
  background-color: #3ad219 !important;
  border-color: #3ad219 !important;
}

.logo-breakpoint {
  background-color: #1d1a27;
}

.p-style-config {
  font-size: 18px;
  color: #4f4a5b;
  margin-left: 25px;
}

.folder-text {
  /* margin-left: 30px; */
  font-size: 22px;
  color: #ffffff;
}

.slist {
  list-style: none;
  padding: 0;
  margin: 0;
}

.viewerbegin-background {
  background-color: #13161f;
  height: 100vh;
  width: 100vw;
}

.text-center {
  text-align: center;
}

.margin-top-15 {
  margin-top: 15%;
}

.viewerbegin-title {
  color: #ffffffff;
  font-size: 18px;
  text-transform: uppercase;
}

.margin-top-5 {
  margin-top: 5%;
}

.viewerbegin-confirm-btn {
  margin-top: 20px;
}

.begin-text {
  color: #ffffffff;
  position: absolute;
  top: 490px;
  left: 935px;
}

.text-color {
  color: #ffffff;
  font-size: 12px;
}

.createanswer-scroll {
  overflow: hidden;
  overflow-y: auto;
  max-height: 600px;
}

.createanswer-scrollbar::-webkit-scrollbar {
  width: 5px;
  left: -100px;

  /* margin-left: -50px !important; */
  position: absolute;
  z-index: 1;
}

.row-margin1 {
  margin-top: 40px !important;
}

.white-p {
  color: white;
}

.enter-answers-p {
  font-size: 16px;
  padding-left: 1rem;
}

.row-margin2 * {
  margin-top: 10px;
}

.enter-answer-box {
  margin-left: 5%;
}

.input-answer {
  width: 100%;
  height: 60px;
  background-color: #231f2e;
  border: 1px solid #4f4a5b;
  color: #ffffff;
  font-size: 15px;
  text-indent: 15px;
}

.type5 {
  height: 32px;
  width: 32px !important;
  background: #a237f5;
  border: 1px solid #000;
  margin-top: 50% !important;
  cursor: pointer;
}

.type4 {
  height: 32px;
  width: 32px !important;
  background: none;
  border: 2px solid #a237f5;
  margin-top: 50% !important;
  cursor: pointer;
}

.type7 {
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  margin-top: 6px;
  margin-left: 20%;
  /* border: 1px solid rgb(255, 255, 255); */
  /* margin-left: 100px; */
  /* margin-top: 2.5% !important; */
}

.circleBase1 {
  border-radius: 100%;
  background-color: #ffffff;

  /* z-index: 1; */
}

.time-to-answer-text {
  margin-left: 20px;
}

.input-time-align {
  margin-left: 20px;
}

.input-time {
  background-color: #1d1a27;
  border: 1px solid #4f4a5b;
  color: #ffffff;
  font-size: 20px;
  height: 60px;
  /* max-width: 150px; */
  /* margin: 19px auto 0 auto; */
  width: 90%;
  text-indent: 15px;
}

.input-time:focus {
  outline: none;
}

.create-answer-btn-row {
  margin-top: 112% !important;
}

.green-box {
  background-color: #3ad219;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.clear-answers-text {
  cursor: pointer;
}

.display-none {
  display: none;
}

.add-answer-button {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 55px;
  padding: 16px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  /* thanks landon */
  margin: 50px auto 0 auto;
  border: none;
  margin: 15px 20px 20px 66px;
}

.time-to-answer {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  /* margin-right: ; */
}

.logo-centered {
  position: absolute;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.centered-p {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-bottom: 10%;
  color: white;
}

.select-question-button1 {
  /* width: 150px; */
  /* height: 30px; */
  position: relative;
  left: 50%;
  bottom: 175%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
  /* transform: skewX(15deg); */
  /* -ms-transform-origin-x: skewX(55deg); */
  transform-origin: 15%;
  background-color: #231f2e;
  font-size: 8px;
  border: none;
}

.enter-question-margin {
  margin-top: 5% !important;
}

.centered-p-config {
  text-align: left;
  margin-left: 5%;
  color: white;
}

.enter-question-p-config {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.question-box-config-align {
  /* margin-left: 5% !important; */
  text-align: left;
  margin-left: 5%;
}

.question-box-config:focus {
  outline: none;
}

.question-box-config {
  background-color: #231f2e;
  box-shadow: none;
  border-color: #a237f5;
  width: 100%;
  height: 200%;
  color: white;
  font-size: 12px;
  font-size: 20px;
  text-indent: 15px;
}

.create-question-folder-background {
  background-color: #1d1a27;
  height: 100vh;
}

.add-to-section {
  min-height: 400px;

  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
.add-to-section::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.add-to-section::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.add-to-section::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.add-to-section::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.add-to-row {
  margin-top: 37px !important;
}

.add-to-folder-text {
  margin-left: 5%;
  font-size: 18px;
  color: #ffffff;
}

.checkbox-row {
  margin-left: 2% !important;
}

.checkbox-margin {
  margin-top: 5px;
}

.create-question-new-quizes-buttons {
  margin-top: 71% !important;
}

.add-to-section-edit {
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
.add-to-section-edit::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.add-to-section-edit::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.add-to-section-edit::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.add-to-section-edit::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.edit-answer-btn-row {
  margin-top: 64% !important;
}

.liveconfig-page {
  background-color: #231f2e;
  height: 100vh;
  width: 100vw;
}

.centered-start-quiz-config {
  margin-top: 20% !important;
}

.startquizconfigdiv {
  text-align: center !important;
}

.createquizbtn {
  background-color: #231f2e !important;
  width: 50% !important;
  border-radius: 0px !important;
  height: 55px;
  font-size: 18px !important;
  background: rgba(255, 255, 255, 0.1) !important;
}

.createquizbtn:hover {
  border-color: #a237f5 !important;
}

.stats-selectrow-config {
  margin-top: 5% !important;
  width: 95%;
  margin-left: 5% !important;
}

.qa-refresh-row {
  margin-bottom: 20px;
}

.q-a-text {
  font-size: 20px;
  color: #ffffff;
}

.edit-refresh-btn {
  cursor: pointer;
  text-align: right;
  fill: rgba(255, 255, 255, 0.2);
  stroke: rgba(255, 255, 255, 0.2);
}

.refresh-icon {
  margin-top: -5px;
  padding-bottom: 5px;
}

.question-info-row {
  max-height: 600px;
  overflow-y: scroll;
}

/* width */
.question-info-row::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.question-info-row::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.question-info-row::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.question-info-row::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.stats-overall-section {
  background-color: #231f2e;
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #4f4a5b;
}

.stats-open {
  border: 1px solid #4f4a5b;
  cursor: pointer;
}

.total-people-font-size {
  font-size: 20px;
}

.stats-correct-answer {
  color: green;
}

.parenthesis-color {
  color: #ffffff;
}

.stats-wrong-answer {
  color: red;
}

.answer-time-stats {
  background: #a237f5;
  width: 10% !important;
  padding-left: 0px !important;
  text-align: center;
  padding-right: 0px !important;
  margin-left: 12px;
  /* margin-bottom: -10px; */
  margin-top: -15px !important;
}

.stats-closed-question {
  background: #4f4a5b;
  width: 10% !important;
  padding-left: 0px !important;
  text-align: center;
  padding-right: 0px !important;
  margin-left: 12px;
  /* margin-bottom: -10px; */
  margin-top: -7px !important;
}

.background-leader-board {
  background-color: #1c1a27;
}

.leaderboard-tab-transparent-mobile {
  display: none;
}

.leaderboard-tab-col {
  background: rgba(32, 27, 44, 0.9) !important;
}

.leaderboard-tab-active {
  cursor: pointer;

  background: #201b2c;
}

.leaderboard-tab-active img {
  width: 30px;
  opacity: 100%;
  margin-top: 8px;
}

.leaderboard-tab {
  cursor: pointer;
}

.leaderboard-tab-active {
  cursor: pointer;

  background: #201b2c;
}

.leaderboard-tab-active img {
  width: 30px;
  opacity: 100%;
  margin-top: 8px;
}

.leaderboard-tab-img {
  width: 25px;
  margin: 8px auto;
}

.leaderboard-tab img {
  width: 30px;
  opacity: 60%;
  margin-top: 8px;
  /* margin: 8px 28px; */
}

.leaderboard-info-tab {
  padding: 10px 0px 10px 0px;
  background-color: #201b2c !important;
}

.leaderboard-card-body {
  background: rgba(32, 27, 44, 0.9) !important;
  transition: all 0.5s ease;
}

.leaderboard-tab-col {
  background: rgba(32, 27, 44, 0.9) !important;
}

.stats-leaderboard-section {
  background-color: #1c1a27;
  font-size: 22px;
  color: #ffffff;
  margin-top: 15px;
}

.leaderboard-text {
  margin-left: 15%;
}

.leaderboard-menu-title {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-right: 16px;
  margin-bottom: 0 !important;
}

.leaderboard-menu-timeframe {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-right: 16px;
  /* margin-top: 6px; */
  margin-bottom: 0 !important;
}

.stats-leaderboard-menu-title {
  margin-left: 15%;
}

.stats-leaderboard-players {
  margin-top: 3% !important;
  max-height: 75vh;
  overflow-x: hidden;
}

.stats-player-points {
  color: #a1a8bc;
}

.hot-cold-wrapper {
  display: inline-block;
  text-align: right;
}

.hot-cold-icon {
  width: 18px;
  margin-top: -2px;
}

.stats-selectrow-config {
  margin-top: 5% !important;
  width: 95%;
  margin-left: 5% !important;
}

.stats-selectrow-config-full {
  width: 70%;
}

.back-arrow {
  cursor: pointer;
}

.backarrow-img {
  margin-right: 20px;
}

.percent-correct-number-txt {
  opacity: 50%;
  color: #ffffff;
  font-size: 16px;
}

.percent-correct-number-txt-correct {
  color: #a237f5;
  font-size: 16px;
}

.percent-correct-answers-txt {
  color: #ffffff;
  font-size: 14px;
  margin-left: 12px;
}

.percent-correct-answers-txt-correct {
  color: #a237f5;
  font-size: 14px;
  margin-left: 12px;
}

.percent-correct-percentage {
  color: #ffffff;
  font-size: 16px;
}

.percent-correct-wrapper {
  text-align: right;
}

.percent-correct-percentage-correct {
  color: #a237f5;
  font-size: 16px;
}

.percent-bar-removepadding {
  padding-right: 0px !important;
}

.bottom-bar {
  margin-bottom: 16px;
  margin-left: auto;
}

.progress-bar {
  background-color: rgba(255, 255, 255, 0.2) !important;
  height: 4px;
  border-radius: 0px;
}

.opacity-selected {
  opacity: 1;
}

.opacity-notselected {
  opacity: 0.4;
}

.total-answers-stats {
  font-size: 18px;
  color: #ffffff;
}

.total-stats-margin {
  text-align: right;
  margin-right: 10px;
}

.stats-answer-name {
  margin-bottom: 20px;
}

.hot-streak-text {
  display: inline-block;
  margin-right: 6px;
  color: #14d03f;
}

.cold-streak-text {
  display: inline-block;
  margin-right: 6px;

  color: #ed3550;
}

.margintop-50 {
  margin-top: 15% !important;
}

.margintop-20 {
  margin-top: 20px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.folder-highlight {
  position: relative;
  z-index: 1;
  margin-top: -64px !important;
  margin-left: -40px !important;
}

.quiz-width {
  width: 30% !important;
}

.quiz-question-text-stats {
  font-size: 18px;
  color: #ffffff;
}

.questions-container {
  height: 700px;
  overflow-y: scroll;
}

.page-container {
  height: 100vh;
}

.stats-page-container {
  height: 100vh;
  background-color: #231f2e;
}

.bg-dark-blue {
  background-color: #141720 !important;
  border: none !important;
  color: white !important;
}

.card-dark-gray {
  background-color: #1f222a !important;
}

.button-hover-effect:hover {
  border-bottom: 4px solid #c5b448 !important;
}

.tableBackground .tableBackground {
  background-color: #1f222a !important;
}

.custom-table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.navbarHeight {
  height: 88px !important;
}

.folder-list,
.game-questions {
  border-radius: 5px;
}

.folder-item,
.game-question {
  color: white;
  cursor: pointer;
}

.folder-list {
  background-color: #1f222a;
}

.game-questions {
  background-color: #1a1d26;
  font-size: 20px;
  color: #a3a5a8;
}

.radial-gradient-button {
  background-image: radial-gradient(circle, #fce552 41%, #f6931f 100%);
  color: #333;
  /* Text color */
  border: none;
  border-radius: "5px";
  padding: "15px 40px";
  font-size: "18px";
  font-weight: "bold";
  width: "100%";
}

.credit-button {
  background-color: #555555;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  padding: 15px 20px;
  min-width: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.credit-button.selected {
  color: #fce552;
  border-bottom: 2px solid #fce552;
}

.credit-button:hover:not(.selected) {
  border: 2px solid #fce552;
  color: #fce552;
}

.credit-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.limit-highlight {
  display: flex;
  align-items: center;
  color: #fce552;
}

.limit-highlight span,
.limit-highlight img {
  /* Highlight color */
  font-weight: bold;
}

.limit-highlight img {
  margin-left: 5px;
  margin-right: 5px;
  height: 14px;
}

.custom-checkbox {
  /* display: none; */
}

.custom-label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 2px solid #7d7d7d;
  border-radius: 3px;
  background-color: #fce552 !important;
}

.custom-checkbox:checked + .custom-label:before {
  content: "\2713";
  text-align: center;
  line-height: 25px;
}

.loading-background {
  background: linear-gradient(
    to bottom,
    #14171f,
    #1e2121 30%,
    #262723 50%,
    #424029 70%,
    #837937
  );
  height: 100%;
  /* Ensure it takes the full height of the viewport */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* Align children vertically */
  justify-content: center;
  /* Center children vertically */
  align-items: center;
}

.LS {
  position: absolute;
  bottom: 0px;
  width: 100%;
  pointer-events: none;
  opacity: 0.1;
}

.save-settings-btn {
  background: linear-gradient(90deg, #fce552 41%, #f6931f 100%);
  border: none;
  border-radius: 5px;
  color: black;
  /* Assuming black text for contrast */
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

input.grey-placeholder::placeholder {
  color: #aaa;
}

.custom-input::placeholder {
  color: white; /* Set placeholder text color to white */
}

.w-100 {
  width: 100%;
}

.selected-choice {
  background-color: #343a40 !important;
}
